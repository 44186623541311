import React from "react"
import { Layout, Parallax } from "@components/layout"
import { Breadcrumbs, AnimatedLink } from "@components/shared"
import { Posts, CategoryItem, BlogHero } from "@components/blog"

import { blog__categoryItem__wrap } from "./styles/blog.module.scss"

const Categories = ({ categories = [], posts = [] }) => {
  return (
    <React.Fragment>
      <h2 style={{ textAlign: "center" }}>Co można tutaj poczytać?</h2>
      <div className={blog__categoryItem__wrap}>
        {categories.map((el, k) => (
          <CategoryItem {...el} key={k} />
        ))}
      </div>
    </React.Fragment>
  )
}
const Blog = ({ pageContext }) => {
  const { posts, page, categories } = pageContext
  let selectedLanguage = "/pl"
  if(typeof window !== "undefined"){
    selectedLanguage = localStorage.getItem("language")
  }
  const translationUrls = {
    pl:`/pl/blog/`,
    en:`/en/blog/`,
  }
  return (
    <Layout {...page[0]} translationUrl={translationUrls}>
      <BlogHero title={page.title} />
      <Breadcrumbs
        elements={[
          {
            label: "blog",
            url: `${selectedLanguage}/blog/`,
          },
        ]}
      />
      <Categories categories={categories} posts={posts} />
    </Layout>
  )
}

export default Blog
